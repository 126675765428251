import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Rules = () => {
  return (
    <>
      <div className="container-header" style={{ paddingTop: 12 }}>
        <Header current="/rules" />
      </div>
      <section className="privacy">
        <div className="container">
          <div className="privacy-block">
            <div className="privacy-wrapper">
              <h2 className="privacy-title">Список усіх доступних папок</h2>

              <div className="privacy-column">
                <a className="privacy-link">
                  Список папок, у яких користувач може створювати записи
                </a>
                <a className="privacy-link">Список полів папки</a>
                <a className="privacy-link">
                  Пошук записів за ідентифікатором або текстовим запитом
                </a>
                <a className="privacy-link">
                  Знайдіть нещодавно створені записи
                </a>
                <a className="privacy-link">
                  Знайдіть нещодавно створені коментарі до записів
                </a>
                <a className="privacy-link">
                  Знайти нещодавно створені журнали викликів
                </a>
                <a className="privacy-link">Знайти нещодавно створені файли</a>
                <a className="privacy-link">
                  Знайдіть нещодавно оновлені записи{" "}
                </a>
              </div>
            </div>

            <div className="privacy-wrapper">
              <h2 className="privacy-title">Політика конфіденційності</h2>

              <p className="privacy-text">
                Захист вашої конфіденційності та персональних даних дуже
                важливий для нас. Тому ми використовуємо ваші персональні дані
                лише в рамках правових норм, зокрема Загального регламенту
                захисту даних. За допомогою цієї політики конфіденційності ми
                хотіли б повідомити вас — незалежно від того, чи створили ви
                обліковий запис:
              </p>

              <ul className="privacy-list">
                <li className="privacy-item">
                  Захист вашої конфіденційності та персональних даних дуже
                  важливий для нас.{" "}
                </li>
                <li className="privacy-item">
                  Тому ми використовуємо ваші персональні дані лише в рамках
                  правових норм, зокрема Загального регламенту захисту даних.{" "}
                </li>
                <li className="privacy-item">
                  За допомогою цієї політики конфіденційності ми хотіли б
                  повідомити вас — незалежно від того, чи створили ви обліковий
                  запис, використовуєте наші різні програми чи відвідали наш
                  веб-сайт — про характер, обсяг і цілі збору, використання та
                  обробки ваших персональних даних.
                </li>
              </ul>
            </div>

            <div className="privacy-wrapper">
              <h2 className="privacy-title">
                Створення облікового запису і підписка.
              </h2>

              <div className="privacy-column">
                <p className="privacy-text">
                  У вас є можливість створити обліковий запис. Ви можете
                  використовувати за допомогою цього облікового запису
                  відповідно до відповідних Умов використання облікового запису
                  (перегляньте також розділ. Для створення облікового запису
                  будуть збережені такі персональні дані:
                </p>

                <p className="privacy-text">Адреса електронної пошти</p>
                <p className="privacy-text">Пароль</p>
                <p className="privacy-text">IP-адреса</p>
                <p className="privacy-text">
                  Ми обробляємо адресу електронної пошти та пароль на підставі
                  ст. 6 абз. 1 речення 1 літ. b) GDPR для виконання договору
                  використання. Ваша IP-адреса обробляється відповідно до ст. 6
                  абз. 1 речення 1 літ. f) GDPR, оскільки в наших законних
                  інтересах зіставляти вашу IP-адресу з IP-адресами, які
                  зловживали нашою послугою в минулому з метою запобігання
                  шахрайству.
                </p>
              </div>
            </div>

            <div className="privacy-wrapper">
              <h2 className="privacy-title">Реєстрація</h2>

              <div className="privacy-column">
                <div className="privacy-text">
                  Якщо після створення облікового запису ви підпишетеся на
                  підписку , додатково будуть зібрані та оброблені наведені
                  нижче персональні дані для цілей укладення та виконання
                  договору.
                </div>

                <div className="privacy-text">
                  Прізвище, ім'я та, якщо можливо, назва компанії
                </div>
                <div className="privacy-text">Адреса</div>
                <div className="privacy-text">Платіжні реквізити</div>
                <div className="privacy-text">Податкові номери (якщо є)</div>
                <div className="privacy-text">Вибраний варіант підписки</div>
                <div className="privacy-text">
                  Можливо, інші додаткові дані, які ви надали під час
                  реєстрації.
                </div>
                <div className="privacy-text">
                  Ваші платіжні дані також будуть оброблені нами, якщо ви
                  спочатку підписалися на безкоштовним пробним періодом.
                  Незважаючи на безкоштовний пробний період, обробка ваших
                  платіжних даних вже необхідна під час укладання договору в
                  розумінні ст. 6 абз. 1 речення 1 літ. b) GDPR, оскільки
                  підписка на в принципі є платною, і ми лише в деяких випадках
                  дозволяємо нашим клієнтам тестувати наші продукти безкоштовно
                  протягом пробного періоду. Однак, якщо протягом
                  вищезазначеного пробного періоду не буде скасовано підписку,
                  підписка автоматично продовжуватиме платити, а вказаний вами
                  спосіб оплати буде списаний у час, зазначений у процесі
                  бронювання.
                </div>
              </div>
            </div>

            <div className="privacy-wrapper">
              <h2 className="privacy-title">Платіжні дані</h2>

              <p className="privacy-text">
                Ваші платіжні дані також будуть оброблені нами, якщо ви спочатку
                підписалися на DeepL Pro з безкоштовним пробним періодом.
                Незважаючи на безкоштовний пробний період, обробка ваших
                платіжних даних вже необхідна під час укладання договору в
                розумінні ст. 6 абз. 1 речення 1 літ. b) GDPR, оскільки підписка
                на DeepL Pro в принципі є платною, і ми лише в деяких випадках
                дозволяємо нашим клієнтам тестувати наші продукти безкоштовно
                протягом пробного періоду. Однак, якщо протягом вищезазначеного
                пробного періоду не буде скасовано підписку, підписка
                автоматично продовжуватиме платити, а вказаний вами спосіб
                оплати буде списаний у час, зазначений у процесі бронювання.
              </p>
            </div>

            <div className="privacy-wrapper">
              <h2 className="privacy-title">Захист вашої конфіденційності</h2>

              <p className="privacy-text">
                Захист вашої конфіденційності та персональних даних дуже
                важливий для нас. Тому ми використовуємо ваші персональні дані
                лише в рамках правових норм, зокрема Загального регламенту
                захисту даних. За допомогою цієї політики конфіденційності ми
                хотіли б повідомити вас — незалежно від того, чи створили ви
                обліковий запис:
              </p>
              <div className="privacy-column">
                <ul className="privacy-list">
                  <li className="privacy-item">
                    Захист вашої конфіденційності та персональних даних дуже
                    важливий для нас.
                  </li>
                  <li className="privacy-item">
                    {" "}
                    Тому ми використовуємо ваші персональні дані лише в рамках
                    правових норм, зокрема Загального регламенту захисту даних.
                  </li>
                  <li className="privacy-item">
                    {" "}
                    За допомогою цієї політики конфіденційності ми хотіли б
                    повідомити вас — незалежно від того, чи створили ви
                    обліковий запис, використовуєте наші різні програми чи
                    відвідали наш веб-сайт — про характер, обсяг і цілі збору,
                    використання та обробки ваших персональних даних.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer current="/rules" />
    </>
  );
};

export default Rules;
