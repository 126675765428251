import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Permission = () => {
  return (
    <>
      <div className="container-header" style={{ paddingTop: 12 }}>
        <Header current="/permission" />
      </div>
      <section className="privacy">
        <div className="container">
          <div className="privacy-block">
            <div className="privacy-wrapper">
              <h2 className="privacy-title">
                Згода на обробку персональних данихі
              </h2>

              <p className="privacy-text">
                Я, користувач сайту, суб'єкт персональних даних, відповідно до
                вказаної мною інформації на Сайті , керуючись Законом України
                «Про захист персональних даних», надаю дозвіл на обробку моїх
                персональних даних (відомостей та сукупності відомостей про
                мене) у Базі користувачів сайту на наступних умовах:
              </p>
              <p className="privacy-text">
                Я підтверджую та визнаю свою повну згоду з такими умовами. Ця
                Згода діє для всіх інтернет-сервісів, інтернет-сайтів та
                інтернет-продуктів, інтернет-ресурсів, які належать або
                використовуються «Власником» у своїй господарській діяльності. Я
                надаю дану згоду відповідно до положень Закону України «Про
                захист персональних даних» у редакції, що діє, на обробку моїх
                особистих персональних даних у розумінні цього закону за
                допомогою інформаційно-телекомунікаційної системи бази
                персональних даних. Мене ознайомили:
              </p>
              <ul className="privacy-list">
                <li className="privacy-item">
                  з метою обробки та зберігання моїх персональних даних: для
                  виконання цілей щодо надання послуг клієнтам, систематизацію
                  процесів виконання послуг та забезпечення оперативного
                  виконання послуг;
                </li>
                <li className="privacy-item">
                  з умовами обробки персональних даних, а також місцем
                  зберігання та обробки персональних даних, визначених у
                  Основних Положеннях про обробку персональних даних.
                </li>
              </ul>

              <p className="privacy-text">
                Послугами у цьому контексті є цивільно-правові відносини,
                надання/отримання послуг та здійснення розрахунків за придбані
                послуги відповідно до чинного законодавства України, зокрема,
                Податкового кодексу України.
              </p>
              <p className="privacy-text">
                Я розумію і даю згоду на те, що під час користування даним
                сайтом та іншими сервісами «Власника» останнім здійснюватиметься
                збір та обробка персональних даних пов'язаних з ідентифікацією,
                відправкою мені інформаційних матеріалів, а також отримання моїх
                особистих персональних даних, у тому числі: прізвище, ім'я, по
                батькові, місце проживання, телефон, електронну адресу, вік,
                файли cookies, ip-адреси, параметри та налаштування
                інтернет-браузерів тощо.
              </p>
              <p className="privacy-text">
                Я згоден з тим, що «Власник» використовує мої дані для націлення
                рекламних та/або інформаційних матеріалів за віком, статтю,
                іншими даними; щодо статистичних досліджень; будь-якими іншими
                способами.
              </p>
              <ul className="privacy-list">
                <li className="privacy-item">
                  Я надаю «Власнику» право передати базу персональних даних або
                  її частину, до якої включено мої персональні дані, без
                  попереднього та подальшого повідомлення мене про таку передачу
                  наступним особам: Партнерам "Власника";
                </li>
                <li className="privacy-item">
                  особам, які є пов'язаними/афілійованими з «Власником»;
                </li>
                <li className="privacy-item">
                  новому власнику сайту та/або сервісу для обробки з метою,
                  передбаченою цією Згодою.
                </li>
              </ul>

              <ul className="privacy-list">
                <li className="privacy-item">
                  Захист вашої конфіденційності та персональних даних дуже
                  важливий для нас.{" "}
                </li>
                <li className="privacy-item">
                  Тому ми використовуємо ваші персональні дані лише в рамках
                  правових норм, зокрема Загального регламенту захисту даних.{" "}
                </li>
                <li className="privacy-item">
                  За допомогою цієї політики конфіденційності ми хотіли б
                  повідомити вас — незалежно від того, чи створили ви обліковий
                  запис, використовуєте наші різні програми чи відвідали наш
                  веб-сайт — про характер, обсяг і цілі збору, використання та
                  обробки ваших персональних даних.
                </li>
              </ul>
            </div>
            <div className="privacy-wrapper">
              <h2 className="privacy-title">
                Основні Положення про обробку персональних даних.
              </h2>

              <div className="privacy-column">
                <h3 className="privacy-second">1. Визначення:</h3>

                <p className="privacy-text">
                  База персональних даних - іменована сукупність упорядкованих
                  персональних даних в електронній формі;
                </p>
                <p className="privacy-text">
                  Власник персональних даних - фізична або юридична особа, що
                  визначає мету обробки персональних даних, встановлює склад цих
                  даних та процедури їх обробки.
                </p>
                <p className="privacy-text">
                  Згода суб'єкта персональних даних — добровільне волевиявлення
                  фізичної особи (за умови її поінформованості) про надання
                  дозволу на обробку її персональних даних відповідно до
                  сформульованої мети їх обробки, вираженої у письмовій формі
                  або у формі, що дозволяє зробити висновок про надання згоди. У
                  сфері електронної комерції згода суб'єкта персональних даних
                  може бути надана при реєстрації в
                  інформаційно-телекомунікаційній системі суб'єкта електронної
                  комерції шляхом проставлення позначки про надання дозволу на
                  обробку своїх персональних даних відповідно до сформульованої
                  мети їх обробки, за умови, що така система не створює
                  можливостей обробки персональних даних досі проставлення
                  позначки;
                </p>
                <p className="privacy-text">
                  Обробка персональних даних — будь-яка дія або сукупність дій,
                  таких як збирання, реєстрація, накопичення, зберігання,
                  адаптування, зміна, поновлення, використання та розповсюдження
                  (поширення, реалізація, передача), знеособлення, знищення
                  персональних даних, у тому числі з використанням інформаційних
                  ( автоматизованих) систем;
                </p>
                <p className="privacy-text">
                  Одержувач — «Власник», фізична особа підприємець,
                  зареєстрований згідно з чинним законодавством України, яке у
                  своїй діяльності використовує цей електронний ресурс, якому
                  надаються персональні дані. Персональні дані - відомості або
                  сукупність відомостей про фізичну особу, яка ідентифікована
                  або може бути конкретно ідентифікована;
                </p>
                <p className="privacy-text">
                  Розпорядник персональних даних - фізична чи юридична особа,
                  якій власником персональних даних чи законом надано право
                  обробляти ці дані від імені власника;
                </p>
                <p className="privacy-text">
                  Суб'єкт персональних даних - фізична особа, персональні дані
                  якої обробляються.
                </p>
                <p className="privacy-text">
                  Партнери – будь-які фізичні чи юридичні особи, пов'язані з
                  «Власником» договірними взаємовідносинами.
                </p>
              </div>
            </div>

            <div className="privacy-wrapper">
              <div className="privacy-column">
                <h3 className="privacy-second">2. Збір персональних даних</h3>

                <p className="privacy-text">
                  2.1. Відповідно до цього положення здійснюється збір та
                  обробка персональних даних користувачів ресурсу «Власника»,
                  які є власниками персональних даних та дали згоду на їх
                  обробку.
                </p>
                <p className="privacy-text">
                  2.2. Персональні дані збираються з метою обробки та зберігання
                  для виконання цілей щодо надання послуг клієнтам,
                  систематизацію процесів виконання послуг та забезпечення
                  оперативного виконання послуг.
                </p>
                <p className="privacy-text">
                  2.3. Обробка персональних даних здійснюється у такому порядку:
                  суб'єкт дає згоду на обробку персональних даних та підтверджує
                  отримання повідомлення про права та дії з персональними
                  даними, після чого здійснюється робота з персональними даними.
                </p>
                <p className="privacy-text">
                  2.4. Згода суб'єкта персональних даних є добровільним
                  волевиявленням фізичної особи про надання дозволу на обробку
                  її персональних даних відповідно до сформульованої мети їх
                  обробки.
                </p>
                <p className="privacy-text">
                  2.5. Згода суб'єкта персональних даних на їхню обробку в
                  контексті цього Положення виходить у формі позначки на
                  електронній сторінці документа або в електронному файлі, що
                  обробляється в інформаційній системі на основі документованих
                  програмно-технічних рішень.
                </p>
                <p className="privacy-text">
                  2.6. Повідомлення суб'єкта персональних даних про включення
                  його персональних даних до бази персональних даних, про права,
                  визначені Законом України «Про захист персональних даних», про
                  мету збору даних та осіб, яким передаються його персональні
                  дані, здійснюється при реєстрації на сайті шляхом ознайомлення
                  з даними умовами.
                </p>
              </div>
            </div>

            <div className="privacy-wrapper">
              <div className="privacy-column">
                <h3 className="privacy-second">
                  3. Обробка персональних даних
                </h3>

                <p className="privacy-text">
                  3.1. Не проводиться обробка персональних даних щодо расового
                  чи етнічного походження, політичних, релігійних чи
                  світоглядних переконань, членства в політичних партіях та
                  професійних спілках, а також даних щодо здоров'я, статевого
                  життя.
                </p>
                <p className="privacy-text">
                  3.2. Архіви персональних даних та їх обробка знаходяться за
                  адресою «Власника» згідно з Єдиним державним реєстром фізичних
                  осіб-підприємців.
                </p>
                <p className="privacy-text">
                  3.3. Порядок доступу третіх осіб до персональних даних
                  визначається умовами згоди суб'єкта персональних даних,
                  наданої власнику бази персональних даних на обробку цих даних,
                  або відповідно до вимог закону.
                </p>
                <p className="privacy-text">
                  3.4. Доступу до персональних даних третій особі не надається,
                  якщо зазначена особа відмовляється взяти на себе зобов'язання
                  щодо забезпечення виконання вимог Закону України «Про захист
                  персональних даних» або не може їх забезпечити.
                </p>
                <p className="privacy-text">
                  3.5. Усі працівники власника бази персональних даних
                  зобов'язані дотримуватися вимог конфіденційності щодо
                  персональних даних та інформації по рахунках у цінних паперах
                  та обігу цінних паперів.
                </p>
                <p className="privacy-text">
                  3.5.1. Працівники, які безпосередньо здійснюють обробку та/або
                  мають доступ до персональних даних у зв'язку з виконанням
                  своїх службових (трудових) обов'язків зобов'язані
                  дотримуватись вимог законодавства України у сфері захисту
                  персональних даних та внутрішніх документів, щодо обробки та
                  захисту персональних даних у базах персональних даних.
                </p>
                <p className="privacy-text">
                  3.5.2. Працівники, які мають доступ до персональних даних, у
                  тому числі, здійснюють їх обробку, зобов'язані не допускати
                  розголошення будь-яким способом персональних даних, які їм
                  були довірені або стали відомі у зв'язку з виконанням
                  професійних, службових або трудових обов'язків. Таке
                  зобов'язання діє і після припинення ними діяльності,
                  пов'язаної з персональними даними, за винятком випадків,
                  встановлених законом.
                </p>
                <p className="privacy-text">
                  3.6. Особи, які мають доступ до персональних даних, у тому
                  числі здійснюють їх обробку у разі порушення ними вимог Закону
                  України «Про захист персональних даних» несуть
                  відповідальність відповідно до законодавства України.
                </p>
                <p className="privacy-text">
                  {" "}
                  3.7. Персональні дані не зберігатимуться довше, ніж це
                  необхідно для мети, для якої такі дані зберігаються, але в
                  будь-якому випадку не більше терміну зберігання даних,
                  визначеного згодою суб'єкта персональних даних на обробку цих
                  даних.
                </p>
              </div>
            </div>

            <div className="privacy-wrapper">
              <div className="privacy-column">
                <h3 className="privacy-second">
                  4. Права суб'єкта персональних даних
                </h3>
                <p className="privacy-text">
                  4.1. Суб'єкт персональних даних має право:
                </p>
                <ul className="privacy-list">
                  <li className="privacy-item">
                    знати про місцезнаходження бази персональних даних, що
                    містить його персональні дані, її призначення та
                    найменування, місцезнаходження та/або місце проживання
                    (перебування) власника або розпорядника цієї бази або дати
                    відповідне доручення про отримання цієї інформації
                    уповноваженим їм особам, крім випадків, встановлених
                    законом;
                  </li>

                  <li className="privacy-item">
                    отримувати інформацію про умови надання доступу до
                    персональних даних, включаючи інформацію про третіх осіб,
                    яким передаються його персональні дані, які містяться у
                    відповідній базі персональних даних;
                  </li>
                  <li className="privacy-item">
                    на доступ до своїх персональних даних, які у відповідній
                    базі персональних даних;
                  </li>
                  <li className="privacy-item">
                    отримувати не пізніше тридцяти календарних днів з дня
                    надходження запиту, крім випадків, передбачених законом,
                    відповідь про те, чи зберігаються його персональні дані у
                    відповідній базі персональних даних, а також отримувати
                    зміст його персональних даних, що зберігаються;
                  </li>
                  <li className="privacy-item">
                    пред'являти вмотивовану вимогу із запереченням проти
                    опрацювання своїх персональних даних органами державної
                    влади, органами місцевого самоврядування при здійсненні
                    повноважень, передбачених законом;
                  </li>
                  <li className="privacy-item">
                    пред'являти вмотивовану вимогу про зміну або знищення своїх
                    персональних даних будь-яким власником та розпорядником цієї
                    бази, якщо ці дані обробляються незаконно або є
                    недостовірними;
                  </li>
                  <li className="privacy-item">
                    на захист своїх персональних даних від незаконної обробки та
                    випадкової втрати, знищення, пошкодження у зв'язку з умисним
                    приховуванням, ненаданням або несвоєчасним їх наданням, а
                    також на захист від надання відомостей, які є недостовірними
                    або ганьблять честь, гідність та ділову репутацію фізичної
                    особи;
                  </li>
                  <li className="privacy-item">
                    звертатися з питань захисту своїх прав персональних даних до
                    органів державної влади, органів місцевого самоврядування,
                    до повноважень яких належить здійснення захисту персональних
                    даних;
                  </li>
                  <li className="privacy-item">
                    застосовувати засоби правового захисту у разі порушення
                    законодавства щодо захисту персональних даних.
                  </li>
                </ul>
                <p className="privacy-text">
                  4.2. Суб'єкт персональних даних має право отримання будь-яких
                  відомостей себе у будь-якого суб'єкта відносин, що з
                  персональними даними, без зазначення мети запиту, крім
                  випадків, встановлених законом. Такий доступ до даних про себе
                  здійснюється безкоштовно.
                </p>
                <p className="privacy-text">
                  4.3. Суб'єкт персональних даних подає запит про доступ (далі -
                  запит) до персональних даних власнику бази персональних даних,
                  який повинен містити: прізвище, ім'я та по батькові, місце
                  проживання (місце знаходження) та реквізити документа, що
                  засвідчує особу суб'єкта персональних даних, щодо якого
                  подається запит, відомості про власника або розпорядника цієї
                  бази, перелік персональних даних, що запитуються.
                </p>
                <p className="privacy-text">
                  4.4. Термін вивчення запиту щодо його задоволення неспроможна
                  перевищувати десяти робочих днів із дня надходження. Протягом
                  цього терміну власник бази персональних даних доводить до
                  відома суб'єкта персональних даних, чи буде запит задоволений
                  чи відповідні персональні дані не підлягають наданню із
                  зазначенням підстави, визначеної у відповідному
                  нормативно-правовому акті. Запит задовольняється протягом
                  тридцяти календарних днів із дня надходження, якщо інше не
                  передбачено законом.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer current="/permission" />
    </>
  );
};

export default Permission;
